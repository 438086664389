'use client';

import { Next13ProgressBar } from 'next13-progressbar';

const ProgressBarWrapper = ({ children }) => {
  return (
    <>
      {children}
      <Next13ProgressBar height="3px" color="#044F62" options={{ showSpinner: true }} showOnShallow />
    </>
  );
};

export default ProgressBarWrapper;