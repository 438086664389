'use client'

import Aos from 'aos'
import { useEffect } from 'react';

export default function AOSWrapper({children}) {
    useEffect(() => {
        Aos.init({ once: true })
    }, []);
    
    return children;
}